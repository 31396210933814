$system-color-red: (
  'red': (
    5: #f9eeee,
    10: #f8e1de,
    20: #f7bbb1,
    30: #f2938c,
    40: #e9695f,
    50: #d83933,
    60: #a23737,
    70: #6f3331,
    80: #3e2927,
    90: #1b1616,
    'vivid': (
      5: false,
      10: #fde0db,
      20: #fdb8ae,
      30: #ff8d7b,
      40: #fb5a47,
      50: #e52207,
      60: #b50909,
      70: #8b0a03,
      80: #5c1111,
      90: false,
    ),
  ),
);