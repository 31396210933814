.usa-skipnav {
  @include border-box-sizing;
  @include typeset;
  background: transparent;
  left: 0;
  padding: units(1) units(2);
  position: absolute;
  top: -3.8rem; // skipnav link height
  transition: all 0.2s ease-in-out;
  z-index: z-index(100);

  &:focus {
    background: color('white');
    left: 0;
    position: absolute;
    top: 0;
    transition: all 0.2s ease-in-out;
  }
}
