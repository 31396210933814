// Buttons variables

$button-stroke: inset 0 0 0 units($theme-button-stroke-width);

// Buttons

.usa-button {
  @include border-box-sizing;
  @include typeset($theme-button-font-family, null, 1);
  @include add-knockout-font-smoothing;
  appearance: none;
  background-color: color('primary');
  border: 0;
  border-radius: radius($theme-button-border-radius);
  color: color('white');
  cursor: pointer;
  display: inline-block;
  font-weight: font-weight('bold');
  margin-right: units(1);
  padding: units(1.5) units(2.5);
  text-align: center;
  text-decoration: none;
  width: 100%;

  @include at-media('mobile-lg') {
    width: auto;
  }

  &:visited {
    color: color('white');
  }

  &:hover,
  &.usa-button--hover {
    background-color: color('primary-dark');
    border-bottom: 0;
    color: color('white');
    text-decoration: none;
  }

  &:active,
  &.usa-button--active {
    background-color: color('primary-darker');
    color: color('white');
  }

  &:focus,
  &.usa-focus {
    outline-offset: units(0.5);
  }

  &:disabled {
    @include button-disabled;
  }
}

.usa-button--accent-cool {
  @include no-knockout-font-smoothing;
  background-color: color('accent-cool');
  color: color('ink');

  &:visited {
    color: color('ink');
  }

  &:hover,
  &.usa-button--hover {
    @include add-knockout-font-smoothing;
    background-color: color('accent-cool-dark');
    color: color('white');
  }

  &:active,
  &.usa-button--active {
    @include add-knockout-font-smoothing;
    background-color: color('accent-cool-darker');
    color: color('white');
  }
}

.usa-button--outline {
  @include no-knockout-font-smoothing;
  background-color: color('transparent');
  box-shadow: $button-stroke color('primary');
  color: color('primary');

  &:visited {
    color: color('primary');
  }

  &:hover,
  &.usa-button--hover {
    background-color: color('transparent');
    box-shadow: $button-stroke color('primary-dark');
    color: color('primary-dark');
  }

  &:active,
  &.usa-button--active {
    background-color: color('transparent');
    box-shadow: $button-stroke color('primary-darker');
    color: color('primary-darker');
  }

  &.usa-button--inverse {
    $button-inverse-color: 'base-lighter';
    $button-inverse-hover-color: 'base-lightest';
    $button-inverse-active-color: 'white';

    box-shadow: $button-stroke color('base-lighter');
    color: color($button-inverse-color);

    &:visited {
      color: color($button-inverse-color);
    }

    &:hover,
    &.usa-button--hover {
      box-shadow: $button-stroke color($button-inverse-hover-color);
      color: color($button-inverse-hover-color);
    }

    &:active,
    &.usa-button--active {
      background-color: transparent;
      box-shadow: $button-stroke color($button-inverse-active-color);
      color: color($button-inverse-active-color);
    }

    &.usa-button--unstyled {
      @include button-unstyled;
      color: color($button-inverse-color);

      &:hover,
      &.usa-button--hover {
        color: color($button-inverse-hover-color);
      }

      &:active,
      &.usa-button--active {
        color: color($button-inverse-active-color);
      }
    }
  }
}

.usa-button--base {
  background-color: color('base');

  &:hover,
  &.usa-button--hover {
    background-color: color('base-dark');
  }

  &:active,
  &.usa-button--active {
    background-color: color('base-darker');
  }
}

.usa-button--secondary {
  background-color: color('secondary');

  &:hover,
  &.usa-button--hover {
    background-color: color('secondary-dark');
  }

  &:active,
  &.usa-button--active {
    background-color: color('secondary-darker');
  }
}

.usa-button--big {
  border-radius: radius('md');
  font-size: font-size($theme-button-font-family, 'lg');
  padding: units(2) units(3);
}

.usa-button--disabled {
  @include button-disabled;
}

.usa-button--outline-disabled,
.usa-button--outline-inverse-disabled,
.usa-button--outline:disabled,
.usa-button--outline-inverse:disabled,
.usa-button--outline-inverse:disabled {
  background-color: color('transparent');
  pointer-events: none;

  &:hover,
  &.usa-button--hover,
  &:active,
  &.usa-button--active,
  &:focus,
  &.usa-focus {
    background-color: color('transparent');
    border: 0;
  }
}

.usa-button--outline-disabled,
.usa-button--outline:disabled {
  box-shadow: $button-stroke color('disabled');
  color: color('disabled');
  &.usa-button--inverse {
    background-color: transparent;
    box-shadow: $button-stroke color('base');
    color: color('base');
  }
}

.usa-button--unstyled {
  @include button-unstyled;
}
