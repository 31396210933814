// Alert variables ---------- //
// TODO: Custom alerts are effectively hidden and not well supported
// Consider removing or rebuilding
$usa-custom-alerts: () !default;
$usa-custom-alerts-bar: () !default;

$usa-alerts: (
  success: 'success-lighter',
  warning: 'warning-lighter',
  error: 'error-lighter',
  info: 'info-lighter',
);

$usa-alerts-bar: (
  success: 'success',
  warning: 'warning',
  error: 'error',
  info: 'info',
);

$alerts: map-merge($usa-alerts, $usa-custom-alerts);
$alerts-bar: map-merge($usa-alerts-bar, $usa-custom-alerts-bar);
$padding-left: units($theme-alert-padding-x) + units($theme-alert-bar-width);

.usa-alert {
  @include typeset($theme-alert-font-family);
  @include border-box-sizing;
  background-color: color('base-lightest');
  background-position: units($theme-alert-padding-x) units($theme-alert-padding-x);
  background-repeat: no-repeat;
  background-size: units(7);
  padding-bottom: units(2);
  padding-left: $padding-left;
  padding-right: units($theme-alert-padding-x);
  padding-top: units($theme-alert-padding-x);
  position: relative;

  * + & {
    margin-top: units(2);
  }

  // TODO: why is this not simply a border?
  &::before {
    background-color: color('base-light');
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: units($theme-alert-bar-width);
  }

  ul {
    margin-bottom: 0;
    margin-top: units(1);
    padding-left: units(1);
  }

  .usa-checklist {
    padding-left: 0;
  }
}

.usa-alert__icon {
  display: table-cell;
  padding-right: units($theme-alert-bar-width);
}

.usa-alert__body {
  display: table-cell;
  vertical-align: top;
}

.usa-alert__heading {
  @include typeset($theme-alert-font-family, 'lg', 2);
  margin-top: 0;
  margin-bottom: units(1);
}

.usa-alert__text {
  @include u-margin-y(0);

  a {
    @include typeset-link;
  }
}

.usa-alert__text:only-child {
  margin-bottom: units($theme-alert-bar-width);
  padding-top: units(0.5);
}

@each $name, $bgcolor in $alerts {
  .usa-alert--#{$name} {
    @include add-background-svg('alerts/#{$name}');
    background-color: color($bgcolor);

    &::before {
      background-color: color(map-get($alerts-bar, $name));
    }

    .usa-alert__body {
      padding-left: units($theme-alert-icon-size) + units($theme-alert-padding-x);
    }
  }
}

.usa-alert--slim {
  background-position: units($theme-alert-padding-x) center;
  background-size: units(5);
  padding-bottom: units($theme-alert-bar-width);
  padding-top: units($theme-alert-bar-width);

  .usa-alert__body {
    padding-left: units(5);
  }

  .usa-alert__text:only-child {
    margin-bottom: units(0.5);
    padding-top: units(0.5);
  }
}

.usa-alert--no-icon {
  background-image: none;

  .usa-alert__body {
    padding-left: 0;
  }
}

.usa-alert--validation {
  background-size: units(5);

  .usa-alert__body {
    padding-left: units(5);
  }

  .usa-checklist {
    margin-top: units(2);
  }
}
