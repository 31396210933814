html {
  @include add-kerning;
  font-family: font-family($theme-body-font-family);
  font-size: $root-font-size;
}

@if $theme-style-body-element {
  body {
    @include typeset;
  }
}

.usa-prose {
  @include typeset($theme-prose-font-family);
  & > {
    @include usa-content-styles;
  }
}

@if $theme-global-paragraph-styles == true {
  @include usa-paragraph-style;
}

@if $theme-global-link-styles == true {
  @include usa-link-style;
}

@if $theme-global-content-styles == true {
  @include usa-content-styles;
}

.usa-paragraph {
  @include typeset-p;
}

.usa-link {
  @include typeset-link;
}

// External link consider "effortless style approach":
// [href^="http:"]:not([href*="my-domain.com"])
// [href^="https:"]:not([href*="my-domain.com"])

.usa-link--external {
  @include external-link(external-link, external-link-hover);

  &.usa-link--alt {
    @include external-link(external-link-alt, external-link-alt-hover);
  }
}

// Remove user agent styles

cite,
var,
address,
dfn {
  font-style: normal;
}

// Custom typography

.usa-content {
  p,
  ul:not(.usa-accordion):not(.usa-accordion--bordered),
  ol:not(.usa-accordion):not(.usa-accordion--bordered) {
    max-width: measure($theme-text-measure);
  }
}

.usa-display {
  @include typeset-h3;
  margin-bottom: 0;

  @include at-media('mobile-lg') {
    @include typeset-h1;
  }

  @include at-media('tablet') {
    @include typeset-display;
  }
}

.usa-intro {
  @include typeset(
    $theme-lead-font-family,
    $theme-lead-font-size,
    $theme-lead-line-height
  );
  font-weight: $theme-font-weight-normal;
  max-width: measure($theme-lead-measure);
}

.usa-dark-background {
  @include add-knockout-font-smoothing;
  background-color: color('base-darker');

  p,
  span {
    color: color('white');
  }

  a {
    color: color('base-lighter');

    &:hover {
      color: color('white');
    }
  }
}

%usa-paragraph {
  @include typeset-p;
}

%usa-heading {
  @include typeset-heading;
}
