.usa-sidenav {
  @include border-box-sizing;
  @include nav-list('sidenav');
  @include typeset($theme-sidenav-font-family, 'sm', 3);
  border-bottom: units(1px) solid color('base-lighter');

  .grid-container & {
    @include u-margin-x(-$theme-site-margins-mobile-width);

    @include at-media('tablet') {
      @include u-margin-x(0);
    }
  }
}

.usa-sidenav__sublist {
  @include nav-sublist;
  font-size: font-size($theme-sidenav-font-family, 'xs');
}
