$system-color-blue-warm: (
  'blue-warm': (
    5: #ecf1f7,
    10: #e1e7f1,
    20: #bbcae4,
    30: #98afd2,
    40: #7292c7,
    50: #4a77b4,
    60: #345d96,
    70: #2f4668,
    80: #252f3e,
    90: #13171f,
    'vivid': (
      5: false,
      10: false,
      20: #b7caf0,
      30: #81aefc,
      40: #5994f6,
      50: #2672de,
      60: #0050d8,
      70: #1a4480,
      80: #162e51,
      90: false,
    ),
  ),
);