// Apply a natural box layout model to all elements, but allowing components to
// change

@if $theme-global-border-box-sizing {
  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
}

body {
  background-color: color('white');
  color: color('ink');
  overflow-x: hidden;
}

// Hack for clearfixes
.lt-ie9 {
  * {
    filter: none !important; /* stylelint-disable-line declaration-no-important */
  }
}

// for IE < 11, see:
// <http://caniuse.com/#feat=hidden>
[hidden] {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

// Apply :focus behavior to focusable elements only (For IE 11)
input,
select,
textarea,
button {
  &:not([disabled]) {
    &:focus {
      @include focus-outline;
    }
  }
}

iframe,
[href],
[tabindex],
[contentEditable=true] {
  &:focus {
    @include focus-outline;
  }
}

.usa-focus {
  @include focus-outline;
}
