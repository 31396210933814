/*
========================================
core/units
----------------------------------------
The master palettes of
- colors
- whitespace
- typescale
----------------------------------------
This file is not meant to be modified
by USWDS project teams. Change your
project values in
project/uswds-project-settings
----------------------------------------
*/

/*
----------------------------------------
Spacing grid multiplier
----------------------------------------
*/

$system-spacing-grid-base: 8px;

/*
----------------------------------------
Spacing grid
----------------------------------------
*/

$neg-prefix: 'neg';
$system-spacing:(
  smaller: (
    '1px':                    1px,
    '2px':                    2px,
  ),
  small: (
    '05':                     spacing-multiple(0.5),  // 4px
    1:                        spacing-multiple(1),   // 8px
    '105':                    spacing-multiple(1.5), // 12px
    2:                        spacing-multiple(2),   // 16px
    '205':                    spacing-multiple(2.5), // 20px
    3:                        spacing-multiple(3),   // 24px
  ),
  smaller-negative: (
    '#{$neg-prefix}-1px':     -1px,
    '#{$neg-prefix}-2px':     -2px,
  ),
  small-negative: (
    '#{$neg-prefix}-05':      spacing-multiple(-0.5),  // -4px
    '#{$neg-prefix}-1':       spacing-multiple(-1),   // -8px
    '#{$neg-prefix}-105':     spacing-multiple(-1.5), // -12px
    '#{$neg-prefix}-2':       spacing-multiple(-2),   // -16px
    '#{$neg-prefix}-205':     spacing-multiple(-2.5), // -20px
    '#{$neg-prefix}-3':       spacing-multiple(-3),   // -24px
  ),
  medium: (
    4:                        spacing-multiple(4),   // 32px
    5:                        spacing-multiple(5),   // 40px
    6:                        spacing-multiple(6),   // 48px
    7:                        spacing-multiple(7),   // 56px
    8:                        spacing-multiple(8),   // 64px
    9:                        spacing-multiple(9),   // 72px
    10:                       spacing-multiple(10),  // 80px
    15:                       spacing-multiple(15),  // 120px
  ),
  medium-negative: (
    '#{$neg-prefix}-4':       spacing-multiple(-4),  // -32px
    '#{$neg-prefix}-5':       spacing-multiple(-5),  // -40px
    '#{$neg-prefix}-6':       spacing-multiple(-6),  // -48px
    '#{$neg-prefix}-7':       spacing-multiple(-7),  // -56px
    '#{$neg-prefix}-8':       spacing-multiple(-8),  // -64px
    '#{$neg-prefix}-9':       spacing-multiple(-9),  // -72px
    '#{$neg-prefix}-10':      spacing-multiple(-10), // -80px
    '#{$neg-prefix}-15':      spacing-multiple(-15), // -120px
  ),
  large: (
    'card':                   spacing-multiple(20),  // 160px
    'card-lg':                spacing-multiple(30),  // 240px
    'mobile':                 spacing-multiple(40),  // 320px
  ),
  larger: (
    'mobile-lg':              spacing-multiple(60),  // 480px
    'tablet':                 spacing-multiple(80),  // 640px
    'tablet-lg':              spacing-multiple(110), // 880px
  ),
  largest: (
    'desktop':                spacing-multiple(128), // 1024px
    'desktop-lg':             spacing-multiple(150), // 1200px
    'widescreen':             spacing-multiple(175), // 1400px
  ),
  special: (
    0:      0,
    'auto': auto,
  ),
);

$system-column-gaps: (
  0:        0,
  '2px':    2px,
  '05':     '05',
  1:        1,
  2:        2,
  3:        3,
  4:        4,
  5:        5,
  6:        6,
);

/*
----------------------------------------
Breakpoints
----------------------------------------
*/

$system-breakpoints: map-collect(
  map-get($system-spacing, large),
  map-get($system-spacing, larger),
  map-get($system-spacing, largest)
);

/*
----------------------------------------
Units
----------------------------------------
*/

$system-spacing-em: (
  small: (
    '05em':  .5em,
    1em:     1em,
    105em:   1.5em,
    2em:     2em,
  ),
);

/*
----------------------------------------
Layout grid widths
----------------------------------------
*/

$system-layout-grid-widths: (
  1:   100% * (1 / 12),
  2:   100% * (2 / 12),
  3:   100% * (3 / 12),
  4:   100% * (4 / 12),
  5:   100% * (5 / 12),
  6:   100% * (6 / 12),
  7:   100% * (7 / 12),
  8:   100% * (8 / 12),
  9:   100% * (9 / 12),
  10:  100% * (10 / 12),
  11:  100% * (11 / 12),
  12:  100% * (12 / 12),
);

/*
----------------------------------------
Font stacks
----------------------------------------
*/

$font-stack-system: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-stack-georgia: 'Georgia', 'Cambria', 'Times New Roman', 'Times', serif;
$font-stack-helvetica: 'Helvetica Neue', 'Helvetica', 'Roboto', 'Arial', sans-serif;
$font-stack-monospace: 'Bitstream Vera Sans Mono', 'Consolas', 'Courier', monospace;

/*
----------------------------------------
Typeface
----------------------------------------
*/

$system-typeface-tokens: (
  'system': (
    display-name: null,
    cap-height: 362px,
    stack: $font-stack-system,
    system-font: true,
  ),
  'georgia': (
    display-name: 'Georgia',
    cap-height: 346px,
    stack: $font-stack-georgia,
    system-font: true,
  ),
  'helvetica': (
    display-name: 'Helvetica Neue',
    cap-height: 357px,
    stack: $font-stack-helvetica,
    system-font: true,
  ),
  'tahoma': (
    display-name: 'Tahoma',
    cap-height: 363px,
    stack: $font-stack-system,
  ),
  'verdana': (
    display-name: 'Verdana',
    cap-height: 364px,
    stack: $font-stack-system,
  ),
  'open-sans': (
    display-name: 'Open Sans',
    cap-height: 357px,
    stack: $font-stack-system,
  ),
  'merriweather': (
    display-name: 'Merriweather Web',
    cap-height: 371px,
    stack: $font-stack-georgia,
    src: (
      dir: 'merriweather',
      roman: (
        100: false,
        200: false,
        300: 'Latin-Merriweather-Light',
        400: 'Latin-Merriweather-Regular',
        500: false,
        600: false,
        700: 'Latin-Merriweather-Bold',
        800: false,
        900: 'Latin-Merriweather-Black',
      ),
      italic: (
        100: false,
        200: false,
        300: 'Latin-Merriweather-LightItalic',
        400: 'Latin-Merriweather-Italic',
        500: false,
        600: false,
        700: 'Latin-Merriweather-BoldItalic',
        800: false,
        900: 'Latin-Merriweather-BlackItalic',
      ),
    ),
  ),
  'roboto-mono': (
    display-name: 'Roboto Mono Web',
    cap-height: 380px,
    stack: $font-stack-monospace,
    src: (
      dir: 'roboto-mono',
      roman: (
        100: 'roboto-mono-v5-latin-100',
        200: false,
        300: 'roboto-mono-v5-latin-300',
        400: 'roboto-mono-v5-latin-regular',
        500: 'roboto-mono-v5-latin-500',
        600: false,
        700: 'roboto-mono-v5-latin-700',
        800: false,
        900: false,
      ),
      italic: (
        100: 'roboto-mono-v5-latin-100italic',
        200: false,
        300: 'roboto-mono-v5-latin-300italic',
        400: 'roboto-mono-v5-latin-italic',
        500: 'roboto-mono-v5-latin-500italic',
        600: false,
        700: 'roboto-mono-v5-latin-700italic',
        800: false,
        900: false,
      ),
    ),
  ),
  'source-sans-pro': (
    display-name: 'Source Sans Pro Web',
    cap-height: 340px,
    stack: $font-stack-helvetica,
    src: (
      dir: 'source-sans-pro',
      roman: (
        100: false,
        200: 'sourcesanspro-extralight-webfont',
        300: 'sourcesanspro-light-webfont',
        400: 'sourcesanspro-regular-webfont',
        500: false,
        600: 'sourcesanspro-semibold-webfont',
        700: 'sourcesanspro-bold-webfont',
        800: false,
        900: 'sourcesanspro-black-webfont',
      ),
      italic: (
        100: false,
        200: 'sourcesanspro-extralightitalic-webfont',
        300: 'sourcesanspro-lightitalic-webfont',
        400: 'sourcesanspro-italic-webfont',
        500: false,
        600: 'sourcesanspro-semibolditalic-webfont',
        700: 'sourcesanspro-bolditalic-webfont',
        800: false,
        900: 'sourcesanspro-blackitalic-webfont',
      ),
    ),
  ),
  'public-sans': (
    display-name: 'Public Sans Web',
    cap-height: 362px,
    stack: $font-stack-system,
    src: (
      dir: 'public-sans',
      roman: (
        100: 'PublicSans-Thin',
        200: 'PublicSans-ExtraLight',
        300: 'PublicSans-Light',
        400: 'PublicSans-Regular',
        500: 'PublicSans-Medium',
        600: 'PublicSans-SemiBold',
        700: 'PublicSans-Bold',
        800: 'PublicSans-ExtraBold',
        900: 'PublicSans-Black',
      ),
      italic: (
        100: 'PublicSans-ThinItalic',
        200: 'PublicSans-ExtraLightItalic',
        300: 'PublicSans-LightItalic',
        400: 'PublicSans-Italic',
        500: 'PublicSans-MediumItalic',
        600: 'PublicSans-SemiBoldItalic',
        700: 'PublicSans-BoldItalic',
        800: 'PublicSans-ExtraBoldItalic',
        900: 'PublicSans-BlackItalic',
      ),
    ),
  ),
);

$all-typeface-tokens: map-merge(
  $system-typeface-tokens,
  $theme-typeface-tokens
);

/*
----------------------------------------
Typescale
----------------------------------------
*/

$system-type-scale: (
  'micro':   10px,
  1:         12px,
  2:         13px,
  3:         14px,
  4:         15px,
  5:         16px,
  6:         17px,
  7:         18px,
  8:         20px,
  9:         22px,
  10:        24px,
  11:        28px,
  12:        32px,
  13:        36px,
  14:        40px,
  15:        48px,
  16:        56px,
  17:        64px,
  18:        80px,
  19:        120px,
  20:        140px,
);

/*
----------------------------------------
Measure
----------------------------------------
*/

$system-measure-smaller:      44ex;
$system-measure-small:        60ex;
$system-measure-base:         64ex;
$system-measure-large:        68ex;
$system-measure-larger:       72ex;
$system-measure-largest:      88ex;

/*
----------------------------------------
Line height
----------------------------------------
*/

$system-line-height: (
  1:    1,
  2:    1.2,
  3:    1.35,
  4:    1.5,
  5:    1.62,
  6:    1.75,
);

/*
----------------------------------------
Base cap height
----------------------------------------
500px 'N' height measured in Sketch.
Used for normalizing font sizes.
Current normalized to Apple system
fonts.
----------------------------------------
*/

$system-base-cap-height: 362px;

/*
----------------------------------------
Colors
----------------------------------------
*/

@import './system-tokens/red-cool';
@import './system-tokens/red';
@import './system-tokens/red-warm';
@import './system-tokens/orange-warm';
@import './system-tokens/orange';
@import './system-tokens/gold';
@import './system-tokens/yellow';
@import './system-tokens/green-warm';
@import './system-tokens/green';
@import './system-tokens/green-cool';
@import './system-tokens/mint';
@import './system-tokens/mint-cool';
@import './system-tokens/cyan';
@import './system-tokens/blue-cool';
@import './system-tokens/blue';
@import './system-tokens/blue-warm';
@import './system-tokens/indigo-cool';
@import './system-tokens/indigo';
@import './system-tokens/indigo-warm';
@import './system-tokens/violet';
@import './system-tokens/violet-warm';
@import './system-tokens/magenta';
@import './system-tokens/gray-cool';
@import './system-tokens/gray';
@import './system-tokens/gray-warm';

$system-color-black-transparent: (
  'black-transparent': (
    5 :     rgba(0, 0, 0, 0.01),
    10:     rgba(0, 0, 0, 0.1),
    20:     rgba(0, 0, 0, 0.2),
    30:     rgba(0, 0, 0, 0.3),
    40:     rgba(0, 0, 0, 0.4),
    50:     rgba(0, 0, 0, 0.5),
    60:     rgba(0, 0, 0, 0.6),
    70:     rgba(0, 0, 0, 0.7),
    80:     rgba(0, 0, 0, 0.8),
    90:     rgba(0, 0, 0, 0.9),
  ),
);

$system-color-white-transparent: (
  'white-transparent': (
    5 :     rgba(255, 255, 255, 0.01),
    10:     rgba(255, 255, 255, 0.1),
    20:     rgba(255, 255, 255, 0.2),
    30:     rgba(255, 255, 255, 0.3),
    40:     rgba(255, 255, 255, 0.4),
    50:     rgba(255, 255, 255, 0.5),
    60:     rgba(255, 255, 255, 0.6),
    70:     rgba(255, 255, 255, 0.7),
    80:     rgba(255, 255, 255, 0.8),
    90:     rgba(255, 255, 255, 0.9),
  ),
);

$system-colors: map-collect(
  $system-color-red-cool,
  $system-color-red,
  $system-color-red-warm,
  $system-color-orange-warm,
  $system-color-orange,
  $system-color-gold,
  $system-color-yellow,
  $system-color-green-warm,
  $system-color-green,
  $system-color-green-cool,
  $system-color-mint,
  $system-color-mint-cool,
  $system-color-cyan,
  $system-color-blue-cool,
  $system-color-blue,
  $system-color-blue-warm,
  $system-color-indigo-cool,
  $system-color-indigo,
  $system-color-indigo-warm,
  $system-color-violet,
  $system-color-violet-warm,
  $system-color-magenta,
  $system-color-gray-cool,
  $system-color-gray,
  $system-color-gray-warm,
  $system-color-black-transparent,
  $system-color-white-transparent
);

$tokens-color-required: (
  'transparent':    transparent,
  'black':          black,
  'white':          white,
);

$tokens-color-basic: (
  'red':                get-system-color('red', 50, 'vivid'),
  'red-warm':           false,
  'orange-warm':        false,
  'orange':             get-system-color('orange', 40, 'vivid'),
  'gold':               get-system-color('gold', 20, 'vivid'),
  'yellow':             get-system-color('yellow', 10, 'vivid'),
  'green-warm':         false,
  'green':              get-system-color('green', 50, 'vivid'),
  'green-cool':         false,
  'mint':               get-system-color('mint', 30, 'vivid'),
  'mint-cool':          false,
  'cyan':               get-system-color('cyan', 40, 'vivid'),
  'blue':               get-system-color('blue', 50, 'vivid'),
  'blue-warm':          false,
  'indigo-cool':        false,
  'indigo':             get-system-color('indigo', 50),
  'indigo-warm':        false,
  'violet':             get-system-color('violet', 50),
  'violet-warm':        false,
  'magenta':            get-system-color('magenta', 50, 'vivid'),
  'gray-cool':          false,
  'gray':               false,
  'gray-warm':          false,
  'black-transparent':  false,
  'white-transparent':  false,
);

//red-cool
$color-red-cool-5:   get-system-color('red-cool', 5);
$color-red-cool-10:  get-system-color('red-cool', 10);
$color-red-cool-20:  get-system-color('red-cool', 20);
$color-red-cool-30:  get-system-color('red-cool', 30);
$color-red-cool-40:  get-system-color('red-cool', 40);
$color-red-cool-50:  get-system-color('red-cool', 50);
$color-red-cool-60:  get-system-color('red-cool', 60);
$color-red-cool-70:  get-system-color('red-cool', 70);
$color-red-cool-80:  get-system-color('red-cool', 80);
$color-red-cool-90:  get-system-color('red-cool', 90);
$color-red-cool-5v:  get-system-color('red-cool', 5, 'vivid');
$color-red-cool-10v: get-system-color('red-cool', 10, 'vivid');
$color-red-cool-20v: get-system-color('red-cool', 20, 'vivid');
$color-red-cool-30v: get-system-color('red-cool', 30, 'vivid');
$color-red-cool-40v: get-system-color('red-cool', 40, 'vivid');
$color-red-cool-50v: get-system-color('red-cool', 50, 'vivid');
$color-red-cool-60v: get-system-color('red-cool', 60, 'vivid');
$color-red-cool-70v: get-system-color('red-cool', 70, 'vivid');
$color-red-cool-80v: get-system-color('red-cool', 80, 'vivid');
$color-red-cool-90v: get-system-color('red-cool', 90, 'vivid');

//red
$color-red-5:   get-system-color('red', 5);
$color-red-10:  get-system-color('red', 10);
$color-red-20:  get-system-color('red', 20);
$color-red-30:  get-system-color('red', 30);
$color-red-40:  get-system-color('red', 40);
$color-red-50:  get-system-color('red', 50);
$color-red-60:  get-system-color('red', 60);
$color-red-70:  get-system-color('red', 70);
$color-red-80:  get-system-color('red', 80);
$color-red-90:  get-system-color('red', 90);
$color-red-5v:  get-system-color('red', 5, 'vivid');
$color-red-10v: get-system-color('red', 10, 'vivid');
$color-red-20v: get-system-color('red', 20, 'vivid');
$color-red-30v: get-system-color('red', 30, 'vivid');
$color-red-40v: get-system-color('red', 40, 'vivid');
$color-red-50v: get-system-color('red', 50, 'vivid');
$color-red-60v: get-system-color('red', 60, 'vivid');
$color-red-70v: get-system-color('red', 70, 'vivid');
$color-red-80v: get-system-color('red', 80, 'vivid');
$color-red-90v: get-system-color('red', 90, 'vivid');

// red-warm
$color-red-warm-5:    get-system-color('red-warm', 5);
$color-red-warm-10:   get-system-color('red-warm', 10);
$color-red-warm-20:   get-system-color('red-warm', 20);
$color-red-warm-30:   get-system-color('red-warm', 30);
$color-red-warm-40:   get-system-color('red-warm', 40);
$color-red-warm-50:   get-system-color('red-warm', 50);
$color-red-warm-60:   get-system-color('red-warm', 60);
$color-red-warm-70:   get-system-color('red-warm', 70);
$color-red-warm-80:   get-system-color('red-warm', 80);
$color-red-warm-90:   get-system-color('red-warm', 90);
$color-red-warm-5v:   get-system-color('red-warm', 5, 'vivid');
$color-red-warm-10v:  get-system-color('red-warm', 10, 'vivid');
$color-red-warm-20v:  get-system-color('red-warm', 20, 'vivid');
$color-red-warm-30v:  get-system-color('red-warm', 30, 'vivid');
$color-red-warm-40v:  get-system-color('red-warm', 40, 'vivid');
$color-red-warm-50v:  get-system-color('red-warm', 50, 'vivid');
$color-red-warm-60v:  get-system-color('red-warm', 60, 'vivid');
$color-red-warm-70v:  get-system-color('red-warm', 70, 'vivid');
$color-red-warm-80v:  get-system-color('red-warm', 80, 'vivid');
$color-red-warm-90v:  get-system-color('red-warm', 90, 'vivid');

// orange-warm
$color-orange-warm-5:   get-system-color('orange-warm', 5);
$color-orange-warm-10:  get-system-color('orange-warm', 10);
$color-orange-warm-20:  get-system-color('orange-warm', 20);
$color-orange-warm-30:  get-system-color('orange-warm', 30);
$color-orange-warm-40:  get-system-color('orange-warm', 40);
$color-orange-warm-50:  get-system-color('orange-warm', 50);
$color-orange-warm-60:  get-system-color('orange-warm', 60);
$color-orange-warm-70:  get-system-color('orange-warm', 70);
$color-orange-warm-80:  get-system-color('orange-warm', 80);
$color-orange-warm-90:  get-system-color('orange-warm', 90);
$color-orange-warm-5v:  get-system-color('orange-warm', 5, 'vivid');
$color-orange-warm-10v: get-system-color('orange-warm', 10, 'vivid');
$color-orange-warm-20v: get-system-color('orange-warm', 20, 'vivid');
$color-orange-warm-30v: get-system-color('orange-warm', 30, 'vivid');
$color-orange-warm-40v: get-system-color('orange-warm', 40, 'vivid');
$color-orange-warm-50v: get-system-color('orange-warm', 50, 'vivid');
$color-orange-warm-60v: get-system-color('orange-warm', 60, 'vivid');
$color-orange-warm-70v: get-system-color('orange-warm', 70, 'vivid');
$color-orange-warm-80v: get-system-color('orange-warm', 80, 'vivid');
$color-orange-warm-90v: get-system-color('orange-warm', 90, 'vivid');

// orange
$color-orange-5:    get-system-color('orange', 5);
$color-orange-10:   get-system-color('orange', 10);
$color-orange-20:   get-system-color('orange', 20);
$color-orange-30:   get-system-color('orange', 30);
$color-orange-40:   get-system-color('orange', 40);
$color-orange-50:   get-system-color('orange', 50);
$color-orange-60:   get-system-color('orange', 60);
$color-orange-70:   get-system-color('orange', 70);
$color-orange-80:   get-system-color('orange', 80);
$color-orange-90:   get-system-color('orange', 90);
$color-orange-5v:   get-system-color('orange', 5, 'vivid');
$color-orange-10v:  get-system-color('orange', 10, 'vivid');
$color-orange-20v:  get-system-color('orange', 20, 'vivid');
$color-orange-30v:  get-system-color('orange', 30, 'vivid');
$color-orange-40v:  get-system-color('orange', 40, 'vivid');
$color-orange-50v:  get-system-color('orange', 50, 'vivid');
$color-orange-60v:  get-system-color('orange', 60, 'vivid');
$color-orange-70v:  get-system-color('orange', 70, 'vivid');
$color-orange-80v:  get-system-color('orange', 80, 'vivid');
$color-orange-90v:  get-system-color('orange', 90, 'vivid');

// gold
$color-gold-5:    get-system-color('gold', 5);
$color-gold-10:   get-system-color('gold', 10);
$color-gold-20:   get-system-color('gold', 20);
$color-gold-30:   get-system-color('gold', 30);
$color-gold-40:   get-system-color('gold', 40);
$color-gold-50:   get-system-color('gold', 50);
$color-gold-60:   get-system-color('gold', 60);
$color-gold-70:   get-system-color('gold', 70);
$color-gold-80:   get-system-color('gold', 80);
$color-gold-90:   get-system-color('gold', 90);
$color-gold-5v:   get-system-color('gold', 5, 'vivid');
$color-gold-10v:  get-system-color('gold', 10, 'vivid');
$color-gold-20v:  get-system-color('gold', 20, 'vivid');
$color-gold-30v:  get-system-color('gold', 30, 'vivid');
$color-gold-40v:  get-system-color('gold', 40, 'vivid');
$color-gold-50v:  get-system-color('gold', 50, 'vivid');
$color-gold-60v:  get-system-color('gold', 60, 'vivid');
$color-gold-70v:  get-system-color('gold', 70, 'vivid');
$color-gold-80v:  get-system-color('gold', 80, 'vivid');
$color-gold-90v:  get-system-color('gold', 90, 'vivid');

// yellow
$color-yellow-5:    get-system-color('yellow', 5);
$color-yellow-10:   get-system-color('yellow', 10);
$color-yellow-20:   get-system-color('yellow', 20);
$color-yellow-30:   get-system-color('yellow', 30);
$color-yellow-40:   get-system-color('yellow', 40);
$color-yellow-50:   get-system-color('yellow', 50);
$color-yellow-60:   get-system-color('yellow', 60);
$color-yellow-70:   get-system-color('yellow', 70);
$color-yellow-80:   get-system-color('yellow', 80);
$color-yellow-90:   get-system-color('yellow', 90);
$color-yellow-5v:   get-system-color('yellow', 5, 'vivid');
$color-yellow-10v:  get-system-color('yellow', 10, 'vivid');
$color-yellow-20v:  get-system-color('yellow', 20, 'vivid');
$color-yellow-30v:  get-system-color('yellow', 30, 'vivid');
$color-yellow-40v:  get-system-color('yellow', 40, 'vivid');
$color-yellow-50v:  get-system-color('yellow', 50, 'vivid');
$color-yellow-60v:  get-system-color('yellow', 60, 'vivid');
$color-yellow-70v:  get-system-color('yellow', 70, 'vivid');
$color-yellow-80v:  get-system-color('yellow', 80, 'vivid');
$color-yellow-90v:  get-system-color('yellow', 90, 'vivid');

// green-warm
$color-green-warm-5:    get-system-color('green-warm', 5);
$color-green-warm-10:   get-system-color('green-warm', 10);
$color-green-warm-20:   get-system-color('green-warm', 20);
$color-green-warm-30:   get-system-color('green-warm', 30);
$color-green-warm-40:   get-system-color('green-warm', 40);
$color-green-warm-50:   get-system-color('green-warm', 50);
$color-green-warm-60:   get-system-color('green-warm', 60);
$color-green-warm-70:   get-system-color('green-warm', 70);
$color-green-warm-80:   get-system-color('green-warm', 80);
$color-green-warm-90:   get-system-color('green-warm', 90);
$color-green-warm-5v:   get-system-color('green-warm', 5, 'vivid');
$color-green-warm-10v:  get-system-color('green-warm', 10, 'vivid');
$color-green-warm-20v:  get-system-color('green-warm', 20, 'vivid');
$color-green-warm-30v:  get-system-color('green-warm', 30, 'vivid');
$color-green-warm-40v:  get-system-color('green-warm', 40, 'vivid');
$color-green-warm-50v:  get-system-color('green-warm', 50, 'vivid');
$color-green-warm-60v:  get-system-color('green-warm', 60, 'vivid');
$color-green-warm-70v:  get-system-color('green-warm', 70, 'vivid');
$color-green-warm-80v:  get-system-color('green-warm', 80, 'vivid');
$color-green-warm-90v:  get-system-color('green-warm', 90, 'vivid');

// green
$color-green-5:   get-system-color('green', 5);
$color-green-10:  get-system-color('green', 10);
$color-green-20:  get-system-color('green', 20);
$color-green-30:  get-system-color('green', 30);
$color-green-40:  get-system-color('green', 40);
$color-green-50:  get-system-color('green', 50);
$color-green-60:  get-system-color('green', 60);
$color-green-70:  get-system-color('green', 70);
$color-green-80:  get-system-color('green', 80);
$color-green-90:  get-system-color('green', 90);
$color-green-5v:  get-system-color('green', 5, 'vivid');
$color-green-10v: get-system-color('green', 10, 'vivid');
$color-green-20v: get-system-color('green', 20, 'vivid');
$color-green-30v: get-system-color('green', 30, 'vivid');
$color-green-40v: get-system-color('green', 40, 'vivid');
$color-green-50v: get-system-color('green', 50, 'vivid');
$color-green-60v: get-system-color('green', 60, 'vivid');
$color-green-70v: get-system-color('green', 70, 'vivid');
$color-green-80v: get-system-color('green', 80, 'vivid');
$color-green-90v: get-system-color('green', 90, 'vivid');

// green-cool
$color-green-cool-5:    get-system-color('green-cool', 5);
$color-green-cool-10:   get-system-color('green-cool', 10);
$color-green-cool-20:   get-system-color('green-cool', 20);
$color-green-cool-30:   get-system-color('green-cool', 30);
$color-green-cool-40:   get-system-color('green-cool', 40);
$color-green-cool-50:   get-system-color('green-cool', 50);
$color-green-cool-60:   get-system-color('green-cool', 60);
$color-green-cool-70:   get-system-color('green-cool', 70);
$color-green-cool-80:   get-system-color('green-cool', 80);
$color-green-cool-90:   get-system-color('green-cool', 90);
$color-green-cool-5v:   get-system-color('green-cool', 5, 'vivid');
$color-green-cool-10v:  get-system-color('green-cool', 10, 'vivid');
$color-green-cool-20v:  get-system-color('green-cool', 20, 'vivid');
$color-green-cool-30v:  get-system-color('green-cool', 30, 'vivid');
$color-green-cool-40v:  get-system-color('green-cool', 40, 'vivid');
$color-green-cool-50v:  get-system-color('green-cool', 50, 'vivid');
$color-green-cool-60v:  get-system-color('green-cool', 60, 'vivid');
$color-green-cool-70v:  get-system-color('green-cool', 70, 'vivid');
$color-green-cool-80v:  get-system-color('green-cool', 80, 'vivid');
$color-green-cool-90v:  get-system-color('green-cool', 90, 'vivid');

// mint
$color-mint-5:    get-system-color('mint', 5);
$color-mint-10:   get-system-color('mint', 10);
$color-mint-20:   get-system-color('mint', 20);
$color-mint-30:   get-system-color('mint', 30);
$color-mint-40:   get-system-color('mint', 40);
$color-mint-50:   get-system-color('mint', 50);
$color-mint-60:   get-system-color('mint', 60);
$color-mint-70:   get-system-color('mint', 70);
$color-mint-80:   get-system-color('mint', 80);
$color-mint-90:   get-system-color('mint', 90);
$color-mint-5v:   get-system-color('mint', 5, 'vivid');
$color-mint-5v:   get-system-color('mint', 5, 'vivid');
$color-mint-10v:  get-system-color('mint', 10, 'vivid');
$color-mint-20v:  get-system-color('mint', 20, 'vivid');
$color-mint-30v:  get-system-color('mint', 30, 'vivid');
$color-mint-40v:  get-system-color('mint', 40, 'vivid');
$color-mint-50v:  get-system-color('mint', 50, 'vivid');
$color-mint-60v:  get-system-color('mint', 60, 'vivid');
$color-mint-70v:  get-system-color('mint', 70, 'vivid');
$color-mint-80v:  get-system-color('mint', 80, 'vivid');
$color-mint-90v:  get-system-color('mint', 90, 'vivid');

// mint-cool
$color-mint-cool-5:   get-system-color('mint-cool', 5);
$color-mint-cool-10:  get-system-color('mint-cool', 10);
$color-mint-cool-20:  get-system-color('mint-cool', 20);
$color-mint-cool-30:  get-system-color('mint-cool', 30);
$color-mint-cool-40:  get-system-color('mint-cool', 40);
$color-mint-cool-50:  get-system-color('mint-cool', 50);
$color-mint-cool-60:  get-system-color('mint-cool', 60);
$color-mint-cool-70:  get-system-color('mint-cool', 70);
$color-mint-cool-80:  get-system-color('mint-cool', 80);
$color-mint-cool-90:  get-system-color('mint-cool', 90);
$color-mint-cool-5v:  get-system-color('mint-cool', 5, 'vivid');
$color-mint-cool-10v: get-system-color('mint-cool', 10, 'vivid');
$color-mint-cool-20v: get-system-color('mint-cool', 20, 'vivid');
$color-mint-cool-30v: get-system-color('mint-cool', 30, 'vivid');
$color-mint-cool-40v: get-system-color('mint-cool', 40, 'vivid');
$color-mint-cool-50v: get-system-color('mint-cool', 50, 'vivid');
$color-mint-cool-60v: get-system-color('mint-cool', 60, 'vivid');
$color-mint-cool-70v: get-system-color('mint-cool', 70, 'vivid');
$color-mint-cool-80v: get-system-color('mint-cool', 80, 'vivid');
$color-mint-cool-90v: get-system-color('mint-cool', 90, 'vivid');

// cyan
$color-cyan-5:    get-system-color('cyan', 5);
$color-cyan-10:   get-system-color('cyan', 10);
$color-cyan-20:   get-system-color('cyan', 20);
$color-cyan-30:   get-system-color('cyan', 30);
$color-cyan-40:   get-system-color('cyan', 40);
$color-cyan-50:   get-system-color('cyan', 50);
$color-cyan-60:   get-system-color('cyan', 60);
$color-cyan-70:   get-system-color('cyan', 70);
$color-cyan-80:   get-system-color('cyan', 80);
$color-cyan-90:   get-system-color('cyan', 90);
$color-cyan-5v:   get-system-color('cyan', 5, 'vivid');
$color-cyan-10v:  get-system-color('cyan', 10, 'vivid');
$color-cyan-20v:  get-system-color('cyan', 20, 'vivid');
$color-cyan-30v:  get-system-color('cyan', 30, 'vivid');
$color-cyan-40v:  get-system-color('cyan', 40, 'vivid');
$color-cyan-50v:  get-system-color('cyan', 50, 'vivid');
$color-cyan-60v:  get-system-color('cyan', 60, 'vivid');
$color-cyan-70v:  get-system-color('cyan', 70, 'vivid');
$color-cyan-80v:  get-system-color('cyan', 80, 'vivid');
$color-cyan-90v:  get-system-color('cyan', 90, 'vivid');

// blue-cool
$color-blue-cool-5:    get-system-color('blue-cool', 5);
$color-blue-cool-10:   get-system-color('blue-cool', 10);
$color-blue-cool-20:   get-system-color('blue-cool', 20);
$color-blue-cool-30:   get-system-color('blue-cool', 30);
$color-blue-cool-40:   get-system-color('blue-cool', 40);
$color-blue-cool-50:   get-system-color('blue-cool', 50);
$color-blue-cool-60:   get-system-color('blue-cool', 60);
$color-blue-cool-70:   get-system-color('blue-cool', 70);
$color-blue-cool-80:   get-system-color('blue-cool', 80);
$color-blue-cool-90:   get-system-color('blue-cool', 90);
$color-blue-cool-5v:   get-system-color('blue-cool', 5, 'vivid');
$color-blue-cool-10v:  get-system-color('blue-cool', 10, 'vivid');
$color-blue-cool-20v:  get-system-color('blue-cool', 20, 'vivid');
$color-blue-cool-30v:  get-system-color('blue-cool', 30, 'vivid');
$color-blue-cool-40v:  get-system-color('blue-cool', 40, 'vivid');
$color-blue-cool-50v:  get-system-color('blue-cool', 50, 'vivid');
$color-blue-cool-60v:  get-system-color('blue-cool', 60, 'vivid');
$color-blue-cool-70v:  get-system-color('blue-cool', 70, 'vivid');
$color-blue-cool-80v:  get-system-color('blue-cool', 80, 'vivid');
$color-blue-cool-90v:  get-system-color('blue-cool', 90, 'vivid');

// blue
$color-blue-5:    get-system-color('blue', 5);
$color-blue-10:   get-system-color('blue', 10);
$color-blue-20:   get-system-color('blue', 20);
$color-blue-30:   get-system-color('blue', 30);
$color-blue-40:   get-system-color('blue', 40);
$color-blue-50:   get-system-color('blue', 50);
$color-blue-60:   get-system-color('blue', 60);
$color-blue-70:   get-system-color('blue', 70);
$color-blue-80:   get-system-color('blue', 80);
$color-blue-90:   get-system-color('blue', 90);
$color-blue-5v:   get-system-color('blue', 5, 'vivid');
$color-blue-10v:  get-system-color('blue', 10, 'vivid');
$color-blue-20v:  get-system-color('blue', 20, 'vivid');
$color-blue-30v:  get-system-color('blue', 30, 'vivid');
$color-blue-40v:  get-system-color('blue', 40, 'vivid');
$color-blue-50v:  get-system-color('blue', 50, 'vivid');
$color-blue-60v:  get-system-color('blue', 60, 'vivid');
$color-blue-70v:  get-system-color('blue', 70, 'vivid');
$color-blue-80v:  get-system-color('blue', 80, 'vivid');
$color-blue-90v:  get-system-color('blue', 90, 'vivid');

// blue-warm
$color-blue-warm-5:   get-system-color('blue-warm', 5);
$color-blue-warm-10:  get-system-color('blue-warm', 10);
$color-blue-warm-20:  get-system-color('blue-warm', 20);
$color-blue-warm-30:  get-system-color('blue-warm', 30);
$color-blue-warm-40:  get-system-color('blue-warm', 40);
$color-blue-warm-50:  get-system-color('blue-warm', 50);
$color-blue-warm-60:  get-system-color('blue-warm', 60);
$color-blue-warm-70:  get-system-color('blue-warm', 70);
$color-blue-warm-80:  get-system-color('blue-warm', 80);
$color-blue-warm-90:  get-system-color('blue-warm', 90);
$color-blue-warm-5v:  get-system-color('blue-warm', 5, 'vivid');
$color-blue-warm-10v: get-system-color('blue-warm', 10, 'vivid');
$color-blue-warm-20v: get-system-color('blue-warm', 20, 'vivid');
$color-blue-warm-30v: get-system-color('blue-warm', 30, 'vivid');
$color-blue-warm-40v: get-system-color('blue-warm', 40, 'vivid');
$color-blue-warm-50v: get-system-color('blue-warm', 50, 'vivid');
$color-blue-warm-60v: get-system-color('blue-warm', 60, 'vivid');
$color-blue-warm-70v: get-system-color('blue-warm', 70, 'vivid');
$color-blue-warm-80v: get-system-color('blue-warm', 80, 'vivid');
$color-blue-warm-90v: get-system-color('blue-warm', 90, 'vivid');

// indigo-cool
$color-indigo-cool-5:   get-system-color('indigo-cool', 5);
$color-indigo-cool-10:  get-system-color('indigo-cool', 10);
$color-indigo-cool-20:  get-system-color('indigo-cool', 20);
$color-indigo-cool-30:  get-system-color('indigo-cool', 30);
$color-indigo-cool-40:  get-system-color('indigo-cool', 40);
$color-indigo-cool-50:  get-system-color('indigo-cool', 50);
$color-indigo-cool-60:  get-system-color('indigo-cool', 60);
$color-indigo-cool-70:  get-system-color('indigo-cool', 70);
$color-indigo-cool-80:  get-system-color('indigo-cool', 80);
$color-indigo-cool-90:  get-system-color('indigo-cool', 90);
$color-indigo-cool-5v:  get-system-color('indigo-cool', 5, 'vivid');
$color-indigo-cool-10v: get-system-color('indigo-cool', 10, 'vivid');
$color-indigo-cool-20v: get-system-color('indigo-cool', 20, 'vivid');
$color-indigo-cool-30v: get-system-color('indigo-cool', 30, 'vivid');
$color-indigo-cool-40v: get-system-color('indigo-cool', 40, 'vivid');
$color-indigo-cool-50v: get-system-color('indigo-cool', 50, 'vivid');
$color-indigo-cool-60v: get-system-color('indigo-cool', 60, 'vivid');
$color-indigo-cool-70v: get-system-color('indigo-cool', 70, 'vivid');
$color-indigo-cool-80v: get-system-color('indigo-cool', 80, 'vivid');
$color-indigo-cool-90v: get-system-color('indigo-cool', 90, 'vivid');

// indigo
$color-indigo-5:    get-system-color('indigo', 5);
$color-indigo-10:   get-system-color('indigo', 10);
$color-indigo-20:   get-system-color('indigo', 20);
$color-indigo-30:   get-system-color('indigo', 30);
$color-indigo-40:   get-system-color('indigo', 40);
$color-indigo-50:   get-system-color('indigo', 50);
$color-indigo-60:   get-system-color('indigo', 60);
$color-indigo-70:   get-system-color('indigo', 70);
$color-indigo-80:   get-system-color('indigo', 80);
$color-indigo-90:   get-system-color('indigo', 90);
$color-indigo-5v:   get-system-color('indigo', 5, 'vivid');
$color-indigo-10v:  get-system-color('indigo', 10, 'vivid');
$color-indigo-20v:  get-system-color('indigo', 20, 'vivid');
$color-indigo-30v:  get-system-color('indigo', 30, 'vivid');
$color-indigo-40v:  get-system-color('indigo', 40, 'vivid');
$color-indigo-50v:  get-system-color('indigo', 50, 'vivid');
$color-indigo-60v:  get-system-color('indigo', 60, 'vivid');
$color-indigo-70v:  get-system-color('indigo', 70, 'vivid');
$color-indigo-80v:  get-system-color('indigo', 80, 'vivid');
$color-indigo-90v:  get-system-color('indigo', 90, 'vivid');

// indigo-warm
$color-indigo-warm-5:   get-system-color('indigo-warm', 5);
$color-indigo-warm-10:  get-system-color('indigo-warm', 10);
$color-indigo-warm-20:  get-system-color('indigo-warm', 20);
$color-indigo-warm-30:  get-system-color('indigo-warm', 30);
$color-indigo-warm-40:  get-system-color('indigo-warm', 40);
$color-indigo-warm-50:  get-system-color('indigo-warm', 50);
$color-indigo-warm-60:  get-system-color('indigo-warm', 60);
$color-indigo-warm-70:  get-system-color('indigo-warm', 70);
$color-indigo-warm-80:  get-system-color('indigo-warm', 80);
$color-indigo-warm-90:  get-system-color('indigo-warm', 90);
$color-indigo-warm-5v:  get-system-color('indigo-warm', 5, 'vivid');
$color-indigo-warm-10v: get-system-color('indigo-warm', 10, 'vivid');
$color-indigo-warm-20v: get-system-color('indigo-warm', 20, 'vivid');
$color-indigo-warm-30v: get-system-color('indigo-warm', 30, 'vivid');
$color-indigo-warm-40v: get-system-color('indigo-warm', 40, 'vivid');
$color-indigo-warm-50v: get-system-color('indigo-warm', 50, 'vivid');
$color-indigo-warm-60v: get-system-color('indigo-warm', 60, 'vivid');
$color-indigo-warm-70v: get-system-color('indigo-warm', 70, 'vivid');
$color-indigo-warm-80v: get-system-color('indigo-warm', 80, 'vivid');
$color-indigo-warm-90v: get-system-color('indigo-warm', 90, 'vivid');

// violet
$color-violet-5:    get-system-color('violet', 5);
$color-violet-10:   get-system-color('violet', 10);
$color-violet-20:   get-system-color('violet', 20);
$color-violet-30:   get-system-color('violet', 30);
$color-violet-40:   get-system-color('violet', 40);
$color-violet-50:   get-system-color('violet', 50);
$color-violet-60:   get-system-color('violet', 60);
$color-violet-70:   get-system-color('violet', 70);
$color-violet-80:   get-system-color('violet', 80);
$color-violet-90:   get-system-color('violet', 90);
$color-violet-5v:   get-system-color('violet', 5, 'vivid');
$color-violet-10v:  get-system-color('violet', 10, 'vivid');
$color-violet-20v:  get-system-color('violet', 20, 'vivid');
$color-violet-30v:  get-system-color('violet', 30, 'vivid');
$color-violet-40v:  get-system-color('violet', 40, 'vivid');
$color-violet-50v:  get-system-color('violet', 50, 'vivid');
$color-violet-60v:  get-system-color('violet', 60, 'vivid');
$color-violet-70v:  get-system-color('violet', 70, 'vivid');
$color-violet-80v:  get-system-color('violet', 80, 'vivid');
$color-violet-90v:  get-system-color('violet', 90, 'vivid');

// violet-warm
$color-violet-warm-5 :  get-system-color('violet-warm', 5);
$color-violet-warm-10:  get-system-color('violet-warm', 10);
$color-violet-warm-20:  get-system-color('violet-warm', 20);
$color-violet-warm-30:  get-system-color('violet-warm', 30);
$color-violet-warm-40:  get-system-color('violet-warm', 40);
$color-violet-warm-50:  get-system-color('violet-warm', 50);
$color-violet-warm-60:  get-system-color('violet-warm', 60);
$color-violet-warm-70:  get-system-color('violet-warm', 70);
$color-violet-warm-80:  get-system-color('violet-warm', 80);
$color-violet-warm-90:  get-system-color('violet-warm', 90);
$color-violet-warm-5v:  get-system-color('violet-warm', 5, 'vivid');
$color-violet-warm-10v: get-system-color('violet-warm', 10, 'vivid');
$color-violet-warm-20v: get-system-color('violet-warm', 20, 'vivid');
$color-violet-warm-30v: get-system-color('violet-warm', 30, 'vivid');
$color-violet-warm-40v: get-system-color('violet-warm', 40, 'vivid');
$color-violet-warm-50v: get-system-color('violet-warm', 50, 'vivid');
$color-violet-warm-60v: get-system-color('violet-warm', 60, 'vivid');
$color-violet-warm-70v: get-system-color('violet-warm', 70, 'vivid');
$color-violet-warm-80v: get-system-color('violet-warm', 80, 'vivid');
$color-violet-warm-90v: get-system-color('violet-warm', 90, 'vivid');

// magenta
$color-magenta-5:   get-system-color('magenta', 5);
$color-magenta-10:  get-system-color('magenta', 10);
$color-magenta-20:  get-system-color('magenta', 20);
$color-magenta-30:  get-system-color('magenta', 30);
$color-magenta-40:  get-system-color('magenta', 40);
$color-magenta-50:  get-system-color('magenta', 50);
$color-magenta-60:  get-system-color('magenta', 60);
$color-magenta-70:  get-system-color('magenta', 70);
$color-magenta-80:  get-system-color('magenta', 80);
$color-magenta-90:  get-system-color('magenta', 90);
$color-magenta-5v:  get-system-color('magenta', 5);
$color-magenta-10v: get-system-color('magenta', 10, 'vivid');
$color-magenta-20v: get-system-color('magenta', 20, 'vivid');
$color-magenta-30v: get-system-color('magenta', 30, 'vivid');
$color-magenta-40v: get-system-color('magenta', 40, 'vivid');
$color-magenta-50v: get-system-color('magenta', 50, 'vivid');
$color-magenta-60v: get-system-color('magenta', 60, 'vivid');
$color-magenta-70v: get-system-color('magenta', 70, 'vivid');
$color-magenta-80v: get-system-color('magenta', 80, 'vivid');
$color-magenta-90v: get-system-color('magenta', 90, 'vivid');

// gray-cool
$color-gray-cool-1:  get-system-color('gray-cool', 1);
$color-gray-cool-2:  get-system-color('gray-cool', 2);
$color-gray-cool-3:  get-system-color('gray-cool', 3);
$color-gray-cool-4:  get-system-color('gray-cool', 4);
$color-gray-cool-5:  get-system-color('gray-cool', 5);
$color-gray-cool-10: get-system-color('gray-cool', 10);
$color-gray-cool-20: get-system-color('gray-cool', 20);
$color-gray-cool-30: get-system-color('gray-cool', 30);
$color-gray-cool-40: get-system-color('gray-cool', 40);
$color-gray-cool-50: get-system-color('gray-cool', 50);
$color-gray-cool-60: get-system-color('gray-cool', 60);
$color-gray-cool-70: get-system-color('gray-cool', 70);
$color-gray-cool-80: get-system-color('gray-cool', 80);
$color-gray-cool-90: get-system-color('gray-cool', 90);

// gray
$color-gray-1:   get-system-color('gray', 1);
$color-gray-2:   get-system-color('gray', 2);
$color-gray-3:   get-system-color('gray', 3);
$color-gray-4:   get-system-color('gray', 4);
$color-gray-5:   get-system-color('gray', 5);
$color-gray-10:  get-system-color('gray', 10);
$color-gray-20:  get-system-color('gray', 20);
$color-gray-30:  get-system-color('gray', 30);
$color-gray-40:  get-system-color('gray', 40);
$color-gray-50:  get-system-color('gray', 50);
$color-gray-60:  get-system-color('gray', 60);
$color-gray-70:  get-system-color('gray', 70);
$color-gray-80:  get-system-color('gray', 80);
$color-gray-90:  get-system-color('gray', 90);

// gray-transparent
$color-gray-warm-1:  get-system-color('gray-warm', 1);
$color-gray-warm-2:  get-system-color('gray-warm', 2);
$color-gray-warm-3:  get-system-color('gray-warm', 3);
$color-gray-warm-4:  get-system-color('gray-warm', 4);
$color-gray-warm-5:  get-system-color('gray-warm', 5);
$color-gray-warm-10: get-system-color('gray-warm', 10);
$color-gray-warm-20: get-system-color('gray-warm', 20);
$color-gray-warm-30: get-system-color('gray-warm', 30);
$color-gray-warm-40: get-system-color('gray-warm', 40);
$color-gray-warm-50: get-system-color('gray-warm', 50);
$color-gray-warm-60: get-system-color('gray-warm', 60);
$color-gray-warm-70: get-system-color('gray-warm', 70);
$color-gray-warm-80: get-system-color('gray-warm', 80);
$color-gray-warm-90: get-system-color('gray-warm', 90);

// black-transparent
$color-black-transparent-5:   get-system-color('black-transparent', 5);
$color-black-transparent-10:  get-system-color('black-transparent', 10);
$color-black-transparent-20:  get-system-color('black-transparent', 20);
$color-black-transparent-30:  get-system-color('black-transparent', 30);
$color-black-transparent-40:  get-system-color('black-transparent', 40);
$color-black-transparent-50:  get-system-color('black-transparent', 50);
$color-black-transparent-60:  get-system-color('black-transparent', 60);
$color-black-transparent-70:  get-system-color('black-transparent', 70);
$color-black-transparent-80:  get-system-color('black-transparent', 80);
$color-black-transparent-90:  get-system-color('black-transparent', 90);

// white-transparent
$color-white-transparent-5:   get-system-color('white-transparent', 5);
$color-white-transparent-10:  get-system-color('white-transparent', 10);
$color-white-transparent-20:  get-system-color('white-transparent', 20);
$color-white-transparent-30:  get-system-color('white-transparent', 30);
$color-white-transparent-40:  get-system-color('white-transparent', 40);
$color-white-transparent-50:  get-system-color('white-transparent', 50);
$color-white-transparent-60:  get-system-color('white-transparent', 60);
$color-white-transparent-70:  get-system-color('white-transparent', 70);
$color-white-transparent-80:  get-system-color('white-transparent', 80);
$color-white-transparent-90:  get-system-color('white-transparent', 90);

$system-color-shortcodes: (
  'transparent':          transparent,
  'black':                black,
  'white':                white,
  'red-cool-5':           $color-red-cool-5,
  'red-cool-10':          $color-red-cool-10,
  'red-cool-20':          $color-red-cool-20,
  'red-cool-30':          $color-red-cool-30,
  'red-cool-40':          $color-red-cool-40,
  'red-cool-50':          $color-red-cool-50,
  'red-cool-60':          $color-red-cool-60,
  'red-cool-70':          $color-red-cool-70,
  'red-cool-80':          $color-red-cool-80,
  'red-cool-90':          $color-red-cool-90,
  'red-cool-5v':          $color-red-cool-5v,
  'red-cool-10v':         $color-red-cool-10v,
  'red-cool-20v':         $color-red-cool-20v,
  'red-cool-30v':         $color-red-cool-30v,
  'red-cool-40v':         $color-red-cool-40v,
  'red-cool-50v':         $color-red-cool-50v,
  'red-cool-60v':         $color-red-cool-60v,
  'red-cool-70v':         $color-red-cool-70v,
  'red-cool-80v':         $color-red-cool-80v,
  'red-cool-90v':         $color-red-cool-90v,
  'red-5':                $color-red-5,
  'red-10':               $color-red-10,
  'red-20':               $color-red-20,
  'red-30':               $color-red-30,
  'red-40':               $color-red-40,
  'red-50':               $color-red-50,
  'red-60':               $color-red-60,
  'red-70':               $color-red-70,
  'red-80':               $color-red-80,
  'red-90':               $color-red-90,
  'red-5v':               $color-red-5v,
  'red-10v':              $color-red-10v,
  'red-20v':              $color-red-20v,
  'red-30v':              $color-red-30v,
  'red-40v':              $color-red-40v,
  'red-50v':              $color-red-50v,
  'red-60v':              $color-red-60v,
  'red-70v':              $color-red-70v,
  'red-80v':              $color-red-80v,
  'red-90v':              $color-red-90v,
  'red-warm-5':           $color-red-warm-5,
  'red-warm-10':          $color-red-warm-10,
  'red-warm-20':          $color-red-warm-20,
  'red-warm-30':          $color-red-warm-30,
  'red-warm-40':          $color-red-warm-40,
  'red-warm-50':          $color-red-warm-50,
  'red-warm-60':          $color-red-warm-60,
  'red-warm-70':          $color-red-warm-70,
  'red-warm-80':          $color-red-warm-80,
  'red-warm-90':          $color-red-warm-90,
  'red-warm-5v':          $color-red-warm-5v,
  'red-warm-10v':         $color-red-warm-10v,
  'red-warm-20v':         $color-red-warm-20v,
  'red-warm-30v':         $color-red-warm-30v,
  'red-warm-40v':         $color-red-warm-40v,
  'red-warm-50v':         $color-red-warm-50v,
  'red-warm-60v':         $color-red-warm-60v,
  'red-warm-70v':         $color-red-warm-70v,
  'red-warm-80v':         $color-red-warm-80v,
  'red-warm-90v':         $color-red-warm-90v,
  'orange-warm-5':        $color-orange-warm-5,
  'orange-warm-10':       $color-orange-warm-10,
  'orange-warm-20':       $color-orange-warm-20,
  'orange-warm-30':       $color-orange-warm-30,
  'orange-warm-40':       $color-orange-warm-40,
  'orange-warm-50':       $color-orange-warm-50,
  'orange-warm-60':       $color-orange-warm-60,
  'orange-warm-70':       $color-orange-warm-70,
  'orange-warm-80':       $color-orange-warm-80,
  'orange-warm-90':       $color-orange-warm-90,
  'orange-warm-5v':       $color-orange-warm-5v,
  'orange-warm-10v':      $color-orange-warm-10v,
  'orange-warm-20v':      $color-orange-warm-20v,
  'orange-warm-30v':      $color-orange-warm-30v,
  'orange-warm-40v':      $color-orange-warm-40v,
  'orange-warm-50v':      $color-orange-warm-50v,
  'orange-warm-60v':      $color-orange-warm-60v,
  'orange-warm-70v':      $color-orange-warm-70v,
  'orange-warm-80v':      $color-orange-warm-80v,
  'orange-warm-90v':      $color-orange-warm-90v,
  'orange-5':             $color-orange-5,
  'orange-10':            $color-orange-10,
  'orange-20':            $color-orange-20,
  'orange-30':            $color-orange-30,
  'orange-40':            $color-orange-40,
  'orange-50':            $color-orange-50,
  'orange-60':            $color-orange-60,
  'orange-70':            $color-orange-70,
  'orange-80':            $color-orange-80,
  'orange-90':            $color-orange-90,
  'orange-5v':            $color-orange-5v,
  'orange-10v':           $color-orange-10v,
  'orange-20v':           $color-orange-20v,
  'orange-30v':           $color-orange-30v,
  'orange-40v':           $color-orange-40v,
  'orange-50v':           $color-orange-50v,
  'orange-60v':           $color-orange-60v,
  'orange-70v':           $color-orange-70v,
  'orange-80v':           $color-orange-80v,
  'orange-90v':           $color-orange-90v,
  'gold-5':               $color-gold-5,
  'gold-10':              $color-gold-10,
  'gold-20':              $color-gold-20,
  'gold-30':              $color-gold-30,
  'gold-40':              $color-gold-40,
  'gold-50':              $color-gold-50,
  'gold-60':              $color-gold-60,
  'gold-70':              $color-gold-70,
  'gold-80':              $color-gold-80,
  'gold-90':              $color-gold-90,
  'gold-5v':              $color-gold-5v,
  'gold-10v':             $color-gold-10v,
  'gold-20v':             $color-gold-20v,
  'gold-30v':             $color-gold-30v,
  'gold-40v':             $color-gold-40v,
  'gold-50v':             $color-gold-50v,
  'gold-60v':             $color-gold-60v,
  'gold-70v':             $color-gold-70v,
  'gold-80v':             $color-gold-80v,
  'gold-90v':             $color-gold-90v,
  'yellow-5':             $color-yellow-5,
  'yellow-10':            $color-yellow-10,
  'yellow-20':            $color-yellow-20,
  'yellow-30':            $color-yellow-30,
  'yellow-40':            $color-yellow-40,
  'yellow-50':            $color-yellow-50,
  'yellow-60':            $color-yellow-60,
  'yellow-70':            $color-yellow-70,
  'yellow-80':            $color-yellow-80,
  'yellow-90':            $color-yellow-90,
  'yellow-5v':            $color-yellow-5v,
  'yellow-10v':           $color-yellow-10v,
  'yellow-20v':           $color-yellow-20v,
  'yellow-30v':           $color-yellow-30v,
  'yellow-40v':           $color-yellow-40v,
  'yellow-50v':           $color-yellow-50v,
  'yellow-60v':           $color-yellow-60v,
  'yellow-70v':           $color-yellow-70v,
  'yellow-80v':           $color-yellow-80v,
  'yellow-90v':           $color-yellow-90v,
  'green-warm-5':         $color-green-warm-5,
  'green-warm-10':        $color-green-warm-10,
  'green-warm-20':        $color-green-warm-20,
  'green-warm-30':        $color-green-warm-30,
  'green-warm-40':        $color-green-warm-40,
  'green-warm-50':        $color-green-warm-50,
  'green-warm-60':        $color-green-warm-60,
  'green-warm-70':        $color-green-warm-70,
  'green-warm-80':        $color-green-warm-80,
  'green-warm-90':        $color-green-warm-90,
  'green-warm-5v':        $color-green-warm-5v,
  'green-warm-10v':       $color-green-warm-10v,
  'green-warm-20v':       $color-green-warm-20v,
  'green-warm-30v':       $color-green-warm-30v,
  'green-warm-40v':       $color-green-warm-40v,
  'green-warm-50v':       $color-green-warm-50v,
  'green-warm-60v':       $color-green-warm-60v,
  'green-warm-70v':       $color-green-warm-70v,
  'green-warm-80v':       $color-green-warm-80v,
  'green-warm-90v':       $color-green-warm-90v,
  'green-5':              $color-green-5,
  'green-10':             $color-green-10,
  'green-20':             $color-green-20,
  'green-30':             $color-green-30,
  'green-40':             $color-green-40,
  'green-50':             $color-green-50,
  'green-60':             $color-green-60,
  'green-70':             $color-green-70,
  'green-80':             $color-green-80,
  'green-90':             $color-green-90,
  'green-5v':             $color-green-5v,
  'green-10v':            $color-green-10v,
  'green-20v':            $color-green-20v,
  'green-30v':            $color-green-30v,
  'green-40v':            $color-green-40v,
  'green-50v':            $color-green-50v,
  'green-60v':            $color-green-60v,
  'green-70v':            $color-green-70v,
  'green-80v':            $color-green-80v,
  'green-90v':            $color-green-90v,
  'green-cool-5':         $color-green-cool-5,
  'green-cool-10':        $color-green-cool-10,
  'green-cool-20':        $color-green-cool-20,
  'green-cool-30':        $color-green-cool-30,
  'green-cool-40':        $color-green-cool-40,
  'green-cool-50':        $color-green-cool-50,
  'green-cool-60':        $color-green-cool-60,
  'green-cool-70':        $color-green-cool-70,
  'green-cool-80':        $color-green-cool-80,
  'green-cool-90':        $color-green-cool-90,
  'green-cool-5v':        $color-green-cool-5v,
  'green-cool-10v':       $color-green-cool-10v,
  'green-cool-20v':       $color-green-cool-20v,
  'green-cool-30v':       $color-green-cool-30v,
  'green-cool-40v':       $color-green-cool-40v,
  'green-cool-50v':       $color-green-cool-50v,
  'green-cool-60v':       $color-green-cool-60v,
  'green-cool-70v':       $color-green-cool-70v,
  'green-cool-80v':       $color-green-cool-80v,
  'green-cool-90v':       $color-green-cool-90v,
  'mint-5':               $color-mint-5,
  'mint-10':              $color-mint-10,
  'mint-20':              $color-mint-20,
  'mint-30':              $color-mint-30,
  'mint-40':              $color-mint-40,
  'mint-50':              $color-mint-50,
  'mint-60':              $color-mint-60,
  'mint-70':              $color-mint-70,
  'mint-80':              $color-mint-80,
  'mint-90':              $color-mint-90,
  'mint-5v':              $color-mint-5v,
  'mint-10v':             $color-mint-10v,
  'mint-20v':             $color-mint-20v,
  'mint-30v':             $color-mint-30v,
  'mint-40v':             $color-mint-40v,
  'mint-50v':             $color-mint-50v,
  'mint-60v':             $color-mint-60v,
  'mint-70v':             $color-mint-70v,
  'mint-80v':             $color-mint-80v,
  'mint-90v':             $color-mint-90v,
  'mint-cool-5':          $color-mint-cool-5,
  'mint-cool-10':         $color-mint-cool-10,
  'mint-cool-20':         $color-mint-cool-20,
  'mint-cool-30':         $color-mint-cool-30,
  'mint-cool-40':         $color-mint-cool-40,
  'mint-cool-50':         $color-mint-cool-50,
  'mint-cool-60':         $color-mint-cool-60,
  'mint-cool-70':         $color-mint-cool-70,
  'mint-cool-80':         $color-mint-cool-80,
  'mint-cool-90':         $color-mint-cool-90,
  'mint-cool-5v':         $color-mint-cool-5v,
  'mint-cool-10v':        $color-mint-cool-10v,
  'mint-cool-20v':        $color-mint-cool-20v,
  'mint-cool-30v':        $color-mint-cool-30v,
  'mint-cool-40v':        $color-mint-cool-40v,
  'mint-cool-50v':        $color-mint-cool-50v,
  'mint-cool-60v':        $color-mint-cool-60v,
  'mint-cool-70v':        $color-mint-cool-70v,
  'mint-cool-80v':        $color-mint-cool-80v,
  'mint-cool-90v':        $color-mint-cool-90v,
  'cyan-5':               $color-cyan-5,
  'cyan-10':              $color-cyan-10,
  'cyan-20':              $color-cyan-20,
  'cyan-30':              $color-cyan-30,
  'cyan-40':              $color-cyan-40,
  'cyan-50':              $color-cyan-50,
  'cyan-60':              $color-cyan-60,
  'cyan-70':              $color-cyan-70,
  'cyan-80':              $color-cyan-80,
  'cyan-90':              $color-cyan-90,
  'cyan-5v':              $color-cyan-5v,
  'cyan-10v':             $color-cyan-10v,
  'cyan-20v':             $color-cyan-20v,
  'cyan-30v':             $color-cyan-30v,
  'cyan-40v':             $color-cyan-40v,
  'cyan-50v':             $color-cyan-50v,
  'cyan-60v':             $color-cyan-60v,
  'cyan-70v':             $color-cyan-70v,
  'cyan-80v':             $color-cyan-80v,
  'cyan-90v':             $color-cyan-90v,
  'blue-cool-5':          $color-blue-cool-5,
  'blue-cool-10':         $color-blue-cool-10,
  'blue-cool-20':         $color-blue-cool-20,
  'blue-cool-30':         $color-blue-cool-30,
  'blue-cool-40':         $color-blue-cool-40,
  'blue-cool-50':         $color-blue-cool-50,
  'blue-cool-60':         $color-blue-cool-60,
  'blue-cool-70':         $color-blue-cool-70,
  'blue-cool-80':         $color-blue-cool-80,
  'blue-cool-90':         $color-blue-cool-90,
  'blue-cool-5v':         $color-blue-cool-5v,
  'blue-cool-10v':        $color-blue-cool-10v,
  'blue-cool-20v':        $color-blue-cool-20v,
  'blue-cool-30v':        $color-blue-cool-30v,
  'blue-cool-40v':        $color-blue-cool-40v,
  'blue-cool-50v':        $color-blue-cool-50v,
  'blue-cool-60v':        $color-blue-cool-60v,
  'blue-cool-70v':        $color-blue-cool-70v,
  'blue-cool-80v':        $color-blue-cool-80v,
  'blue-cool-90v':        $color-blue-cool-90v,
  'blue-5':               $color-blue-5,
  'blue-10':              $color-blue-10,
  'blue-20':              $color-blue-20,
  'blue-30':              $color-blue-30,
  'blue-40':              $color-blue-40,
  'blue-50':              $color-blue-50,
  'blue-60':              $color-blue-60,
  'blue-70':              $color-blue-70,
  'blue-80':              $color-blue-80,
  'blue-90':              $color-blue-90,
  'blue-5v':              $color-blue-5v,
  'blue-10v':             $color-blue-10v,
  'blue-20v':             $color-blue-20v,
  'blue-30v':             $color-blue-30v,
  'blue-40v':             $color-blue-40v,
  'blue-50v':             $color-blue-50v,
  'blue-60v':             $color-blue-60v,
  'blue-70v':             $color-blue-70v,
  'blue-80v':             $color-blue-80v,
  'blue-90v':             $color-blue-90v,
  'blue-warm-5':          $color-blue-warm-5,
  'blue-warm-10':         $color-blue-warm-10,
  'blue-warm-20':         $color-blue-warm-20,
  'blue-warm-30':         $color-blue-warm-30,
  'blue-warm-40':         $color-blue-warm-40,
  'blue-warm-50':         $color-blue-warm-50,
  'blue-warm-60':         $color-blue-warm-60,
  'blue-warm-70':         $color-blue-warm-70,
  'blue-warm-80':         $color-blue-warm-80,
  'blue-warm-90':         $color-blue-warm-90,
  'blue-warm-5v':         $color-blue-warm-5v,
  'blue-warm-10v':        $color-blue-warm-10v,
  'blue-warm-20v':        $color-blue-warm-20v,
  'blue-warm-30v':        $color-blue-warm-30v,
  'blue-warm-40v':        $color-blue-warm-40v,
  'blue-warm-50v':        $color-blue-warm-50v,
  'blue-warm-60v':        $color-blue-warm-60v,
  'blue-warm-70v':        $color-blue-warm-70v,
  'blue-warm-80v':        $color-blue-warm-80v,
  'blue-warm-90v':        $color-blue-warm-90v,
  'indigo-cool-5':        $color-indigo-cool-5,
  'indigo-cool-10':       $color-indigo-cool-10,
  'indigo-cool-20':       $color-indigo-cool-20,
  'indigo-cool-30':       $color-indigo-cool-30,
  'indigo-cool-40':       $color-indigo-cool-40,
  'indigo-cool-50':       $color-indigo-cool-50,
  'indigo-cool-60':       $color-indigo-cool-60,
  'indigo-cool-70':       $color-indigo-cool-70,
  'indigo-cool-80':       $color-indigo-cool-80,
  'indigo-cool-90':       $color-indigo-cool-90,
  'indigo-cool-5v':       $color-indigo-cool-5v,
  'indigo-cool-10v':      $color-indigo-cool-10v,
  'indigo-cool-20v':      $color-indigo-cool-20v,
  'indigo-cool-30v':      $color-indigo-cool-30v,
  'indigo-cool-40v':      $color-indigo-cool-40v,
  'indigo-cool-50v':      $color-indigo-cool-50v,
  'indigo-cool-60v':      $color-indigo-cool-60v,
  'indigo-cool-70v':      $color-indigo-cool-70v,
  'indigo-cool-80v':      $color-indigo-cool-80v,
  'indigo-cool-90v':      $color-indigo-cool-90v,
  'indigo-5':             $color-indigo-5,
  'indigo-10':            $color-indigo-10,
  'indigo-20':            $color-indigo-20,
  'indigo-30':            $color-indigo-30,
  'indigo-40':            $color-indigo-40,
  'indigo-50':            $color-indigo-50,
  'indigo-60':            $color-indigo-60,
  'indigo-70':            $color-indigo-70,
  'indigo-80':            $color-indigo-80,
  'indigo-90':            $color-indigo-90,
  'indigo-5v':            $color-indigo-5v,
  'indigo-10v':           $color-indigo-10v,
  'indigo-20v':           $color-indigo-20v,
  'indigo-30v':           $color-indigo-30v,
  'indigo-40v':           $color-indigo-40v,
  'indigo-50v':           $color-indigo-50v,
  'indigo-60v':           $color-indigo-60v,
  'indigo-70v':           $color-indigo-70v,
  'indigo-80v':           $color-indigo-80v,
  'indigo-90v':           $color-indigo-90v,
  'indigo-warm-5':        $color-indigo-warm-5,
  'indigo-warm-10':       $color-indigo-warm-10,
  'indigo-warm-20':       $color-indigo-warm-20,
  'indigo-warm-30':       $color-indigo-warm-30,
  'indigo-warm-40':       $color-indigo-warm-40,
  'indigo-warm-50':       $color-indigo-warm-50,
  'indigo-warm-60':       $color-indigo-warm-60,
  'indigo-warm-70':       $color-indigo-warm-70,
  'indigo-warm-80':       $color-indigo-warm-80,
  'indigo-warm-90':       $color-indigo-warm-90,
  'indigo-warm-5v':       $color-indigo-warm-5v,
  'indigo-warm-10v':      $color-indigo-warm-10v,
  'indigo-warm-20v':      $color-indigo-warm-20v,
  'indigo-warm-30v':      $color-indigo-warm-30v,
  'indigo-warm-40v':      $color-indigo-warm-40v,
  'indigo-warm-50v':      $color-indigo-warm-50v,
  'indigo-warm-60v':      $color-indigo-warm-60v,
  'indigo-warm-70v':      $color-indigo-warm-70v,
  'indigo-warm-80v':      $color-indigo-warm-80v,
  'indigo-warm-90v':      $color-indigo-warm-90v,
  'violet-5':             $color-violet-5,
  'violet-10':            $color-violet-10,
  'violet-20':            $color-violet-20,
  'violet-30':            $color-violet-30,
  'violet-40':            $color-violet-40,
  'violet-50':            $color-violet-50,
  'violet-60':            $color-violet-60,
  'violet-70':            $color-violet-70,
  'violet-80':            $color-violet-80,
  'violet-90':            $color-violet-90,
  'violet-5v':            $color-violet-5v,
  'violet-10v':           $color-violet-10v,
  'violet-20v':           $color-violet-20v,
  'violet-30v':           $color-violet-30v,
  'violet-40v':           $color-violet-40v,
  'violet-50v':           $color-violet-50v,
  'violet-60v':           $color-violet-60v,
  'violet-70v':           $color-violet-70v,
  'violet-80v':           $color-violet-80v,
  'violet-90v':           $color-violet-90v,
  'violet-warm-5 ':       $color-violet-warm-5 ,
  'violet-warm-10':       $color-violet-warm-10,
  'violet-warm-20':       $color-violet-warm-20,
  'violet-warm-30':       $color-violet-warm-30,
  'violet-warm-40':       $color-violet-warm-40,
  'violet-warm-50':       $color-violet-warm-50,
  'violet-warm-60':       $color-violet-warm-60,
  'violet-warm-70':       $color-violet-warm-70,
  'violet-warm-80':       $color-violet-warm-80,
  'violet-warm-90':       $color-violet-warm-90,
  'violet-warm-5v':       $color-violet-warm-5v,
  'violet-warm-10v':      $color-violet-warm-10v,
  'violet-warm-20v':      $color-violet-warm-20v,
  'violet-warm-30v':      $color-violet-warm-30v,
  'violet-warm-40v':      $color-violet-warm-40v,
  'violet-warm-50v':      $color-violet-warm-50v,
  'violet-warm-60v':      $color-violet-warm-60v,
  'violet-warm-70v':      $color-violet-warm-70v,
  'violet-warm-80v':      $color-violet-warm-80v,
  'violet-warm-90v':      $color-violet-warm-90v,
  'magenta-5':            $color-magenta-5,
  'magenta-10':           $color-magenta-10,
  'magenta-20':           $color-magenta-20,
  'magenta-30':           $color-magenta-30,
  'magenta-40':           $color-magenta-40,
  'magenta-50':           $color-magenta-50,
  'magenta-60':           $color-magenta-60,
  'magenta-70':           $color-magenta-70,
  'magenta-80':           $color-magenta-80,
  'magenta-90':           $color-magenta-90,
  'magenta-5v':           $color-magenta-5v,
  'magenta-10v':          $color-magenta-10v,
  'magenta-20v':          $color-magenta-20v,
  'magenta-30v':          $color-magenta-30v,
  'magenta-40v':          $color-magenta-40v,
  'magenta-50v':          $color-magenta-50v,
  'magenta-60v':          $color-magenta-60v,
  'magenta-70v':          $color-magenta-70v,
  'magenta-80v':          $color-magenta-80v,
  'magenta-90v':          $color-magenta-90v,
  'gray-cool-1':          $color-gray-cool-1,
  'gray-cool-2':          $color-gray-cool-2,
  'gray-cool-3':          $color-gray-cool-3,
  'gray-cool-4':          $color-gray-cool-4,
  'gray-cool-5':          $color-gray-cool-5,
  'gray-cool-10':         $color-gray-cool-10,
  'gray-cool-20':         $color-gray-cool-20,
  'gray-cool-30':         $color-gray-cool-30,
  'gray-cool-40':         $color-gray-cool-40,
  'gray-cool-50':         $color-gray-cool-50,
  'gray-cool-60':         $color-gray-cool-60,
  'gray-cool-70':         $color-gray-cool-70,
  'gray-cool-80':         $color-gray-cool-80,
  'gray-cool-90':         $color-gray-cool-90,
  'gray-1':               $color-gray-1,
  'gray-2':               $color-gray-2,
  'gray-3':               $color-gray-3,
  'gray-4':               $color-gray-4,
  'gray-5':               $color-gray-5,
  'gray-10':              $color-gray-10,
  'gray-20':              $color-gray-20,
  'gray-30':              $color-gray-30,
  'gray-40':              $color-gray-40,
  'gray-50':              $color-gray-50,
  'gray-60':              $color-gray-60,
  'gray-70':              $color-gray-70,
  'gray-80':              $color-gray-80,
  'gray-90':              $color-gray-90,
  'gray-warm-1':          $color-gray-warm-1,
  'gray-warm-2':          $color-gray-warm-2,
  'gray-warm-3':          $color-gray-warm-3,
  'gray-warm-4':          $color-gray-warm-4,
  'gray-warm-5':          $color-gray-warm-5,
  'gray-warm-10':         $color-gray-warm-10,
  'gray-warm-20':         $color-gray-warm-20,
  'gray-warm-30':         $color-gray-warm-30,
  'gray-warm-40':         $color-gray-warm-40,
  'gray-warm-50':         $color-gray-warm-50,
  'gray-warm-60':         $color-gray-warm-60,
  'gray-warm-70':         $color-gray-warm-70,
  'gray-warm-80':         $color-gray-warm-80,
  'gray-warm-90':         $color-gray-warm-90,
  'black-transparent-5':  $color-black-transparent-5,
  'black-transparent-10': $color-black-transparent-10,
  'black-transparent-20': $color-black-transparent-20,
  'black-transparent-30': $color-black-transparent-30,
  'black-transparent-40': $color-black-transparent-40,
  'black-transparent-50': $color-black-transparent-50,
  'black-transparent-60': $color-black-transparent-60,
  'black-transparent-70': $color-black-transparent-70,
  'black-transparent-80': $color-black-transparent-80,
  'black-transparent-90': $color-black-transparent-90,
  'white-transparent-5':  $color-white-transparent-5,
  'white-transparent-10': $color-white-transparent-10,
  'white-transparent-20': $color-white-transparent-20,
  'white-transparent-30': $color-white-transparent-30,
  'white-transparent-40': $color-white-transparent-40,
  'white-transparent-50': $color-white-transparent-50,
  'white-transparent-60': $color-white-transparent-60,
  'white-transparent-70': $color-white-transparent-70,
  'white-transparent-80': $color-white-transparent-80,
  'white-transparent-90': $color-white-transparent-90,
);
