// Screen reader only helper
@mixin sr-only {
  position: absolute;
  left: -999em;
}

@mixin add-sr-only {
  position: absolute;
  left: -999em;
}

// This "negates" the sr-only helper; useful if
// we want the default state of something to be
// for screen readers only, but we want to force
// it to show for sighted users under more specific
// conditions.
@mixin not-sr-only {
  position: static;
}

@mixin add-no-sr-only {
  position: static;
}

.usa-sr-only {
  @include sr-only;
}

// Aria hidden helper
@mixin accessibly-hidden {
  &[aria-hidden=true] {
    display: none;
  }
}
