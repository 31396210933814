@mixin add-checkbox-placeholder {
  $placeholder-width: units(4);
  $placeholder-margin: units(1);

  text-indent: ($placeholder-width + $placeholder-margin) * -1;

  &::before {
    content: ' ';
    display: inline-block;
    height: units(2);
    margin-left: units(-0.5);
    margin-right: units(1.5);
    width: $placeholder-width;
  }
}
