$system-color-blue-cool: (
  'blue-cool': (
    5: #e7f2f5,
    10: #dae9ee,
    20: #adcfdc,
    30: #82b4c9,
    40: #6499af,
    50: #3a7d95,
    60: #2e6276,
    70: #224a58,
    80: #14333d,
    90: #0f191c,
    'vivid': (
      5: #e1f3f8,
      10: false,
      20: #97d4ea,
      30: #59b9de,
      40: #28a0cb,
      50: #0d7ea2,
      60: #07648d,
      70: #074b69,
      80: #002d3f,
      90: false,
    ),
  ),
);