// TODO: abstract and integrate
@mixin search-icon {
  @include add-background-svg('search');
  background-position: center center;
  background-size: units(3);
}

.usa-search {
  @include border-box-sizing;
  @include clearfix;
  @include typeset($theme-search-font-family);
  position: relative;

  [role=search] {
    display: flex;
  }

  [type=submit] {
    @include search-icon;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    height: units(4);
    margin: 0;
    padding: 0;
    width: units($theme-button-small-width);

    @include at-media('mobile-lg') {
      @include u-padding-x(2);
      background-image: none;
      width: auto;
    }
  }
}

.usa-search--big {
  $height: units(6);

  [type=search],
  .usa-search__input {
    @include at-media('mobile-lg') {
      font-size: font-size($theme-search-font-family, 'sm');
      height: $height;
    }
  }

  [type=submit],
  .usa-search__submit {
    @include at-media('mobile-lg') {
      @include u-padding-x(4);
      font-size: font-size($theme-search-font-family, 'lg');
      height: $height;
      width: auto;
    }
  }
}

.usa-search--small {
  [type=submit],
  .usa-search__submit {
    @include at-media('mobile-lg') {
      @include search-icon;
      width: units($theme-button-small-width);
    }
  }
}

// Extra specificity to override rules set in normalize.css.
input[type=search] { /* stylelint-disable-line selector-no-qualifying-type */
  box-sizing: border-box;
  appearance: none;
}

[type=search],
.usa-search__input {
  @include u-padding-y(0);
  border-bottom-right-radius: 0;
  border-right: none;
  border-top-right-radius: 0;
  box-sizing: border-box;
  float: left;
  font-size: font-size($theme-search-font-family, 'xs');
  height: units(4);
  margin: 0;
}

.usa-search__submit-text {
  @include sr-only;

  @include at-media('mobile-lg') {
    @include not-sr-only;
  }
}
