@mixin button-unstyled {
  @include no-knockout-font-smoothing;
  @include typeset-link;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  font-weight: font-weight('normal');
  margin: 0;
  padding: 0;
  text-align: left;

  &:hover,
  &:active {
    @include no-knockout-font-smoothing;
    background-color: transparent;
    box-shadow: none;
    text-decoration: underline;
  }
}
