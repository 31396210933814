// Outputs properties based on contents of text()

$text-utililies: (
  font-style: map-collect(
    map-deep-get($system-properties, font-style, standard),
    map-deep-get($system-properties, font-style, extended)
  ),
  font-weight: map-collect(
    map-deep-get($system-properties, font-weight, standard),
    map-deep-get($system-properties, font-weight, extended)
  ),
  letter-spacing: map-collect(
    map-deep-get($system-properties, letter-spacing, standard),
    map-deep-get($system-properties, letter-spacing, extended)
  ),
  text-align: map-collect(
    map-deep-get($system-properties, text-align, standard),
    map-deep-get($system-properties, text-align, extended)
  ),
  text-decoration: map-collect(
    map-deep-get($system-properties, text-decoration, standard),
    map-deep-get($system-properties, text-decoration, extended)
  ),
  text-transform: map-collect(
    map-deep-get($system-properties, text-transform, standard),
    map-deep-get($system-properties, text-transform, extended)
  ),
  vertical-align: map-collect(
    map-deep-get($system-properties, vertical-align, standard),
    map-deep-get($system-properties, vertical-align, extended)
  ),
  white-space: map-collect(
    map-deep-get($system-properties, white-space, standard),
    map-deep-get($system-properties, white-space, extended)
  ),
  color: map-collect(
    $tokens-color-required
  ),
);

@mixin u-text($value...) {
  $important: null;
  @if has-important($value) {
    $value: remove($value, '!important');
    $important: ' !important';
  }
  @each $this-value in $value {
    $this-value: smart-quote($this-value);
    $match: false;
    @if map-has-key($all-color-shortcodes, $this-value) {
      $match: true;
      color: color($this-value) #{$important};
    }
    @else {
      @each $property, $map in $text-utililies {
        @if not $match and map-has-key($map, $this-value) {
          #{$property}: get-uswds-value($property, $this-value...) #{$important};
          $match: true;
        }
      }
    }
    @if not $match {
      @error '`#{$this-value}` is not a valid `text` value.';
    }
  }
}
