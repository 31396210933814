// Outputs line-height

@mixin u-line-height($value...) {
  $value: unpack($value);
  $important: null;
  @if has-important($value) {
    $value: remove($value, '!important');
    $important: ' !important';
  }
  $family: nth($value, 1);
  $scale: nth($value, 2);
  line-height: lh($family, $scale) #{$important};
}
