// Variables

$accordion-border: units($theme-accordion-border-width) solid color($theme-accordion-border-color);

// Accordion Styles

@mixin accordion-list-styles {
  @include unstyled-list;
  color: color('ink');
  margin: 0;
  padding: 0;
  width: 100%;
}

// scss-lint:disable PropertyCount
@mixin accordion-button-styles {
  @include button-unstyled;
  @include add-background-svg('minus');

  background-color: color('base-lightest');
  background-position: right units(2.5) center;
  background-size: units(2);
  color: color('ink');
  cursor: pointer;
  display: inline-block;
  font-weight: font-weight('bold');
  margin: 0;
  padding: units(2) units(2.5) * 2 + units(2) units(2) units(2.5);
  text-decoration: none;
  width: 100%;

  &:hover {
    background-color: color('base-lighter');
    color: color('ink');
    text-decoration: none;
  }
}
// scss-lint:enable PropertyCount

@mixin accordion-button-unopened-styles {
  @include add-background-svg('plus');
  background-size: units(2);
}

@mixin accordion-nested-list {
  > ul li ul {
    list-style: disc;
    > li > ul {
      list-style: circle;
      > li > ul {
        list-style: square;
      }
    }
  }
}

.usa-accordion {
  @include accordion-list-styles;
  @include accordion-nested-list;
  @include border-box-sizing;
  @include typeset($theme-accordion-font-family);

  + .usa-accordion,
  + .usa-accordion--bordered {
    margin-top: units(1);
  }
}

.usa-accordion--bordered {
  .usa-accordion__content {
    border-bottom: $accordion-border;
    border-left: $accordion-border;
    border-right: $accordion-border;
    padding-bottom: units(2);
  }

  .usa-accordion__heading {
    margin-bottom: 0;
  }
}

.usa-accordion__heading,
// kludge to override .usa-prose styles
// TODO: work this into a mixin
.usa-prose .usa-accordion__heading {
  @include typeset($theme-accordion-font-family, $theme-body-font-size, 1);
  margin: 0;

  &:not(:first-child) {
    margin-top: units(1);
  }
}

.usa-accordion__content {
  background-color: color('white');
  margin-top: 0;
  overflow: auto;
  padding: units(2) units(2.5) calc(#{units(2)} - #{units(0.5)}) units(2.5);

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  @include accessibly-hidden;
}

.usa-accordion__button {
  @include accordion-button-styles;
}

.usa-accordion__button[aria-expanded=false] {
  @include accordion-button-unopened-styles;
}
