%usa-table {
  @include border-box-sizing;
  @include typeset;
  border-spacing: 0;
  margin: units(2.5) 0;

  thead {
    th {
      font-weight: $theme-font-weight-bold;
    }

    th,
    td {
      background-color: color('base-lightest');
    }
  }

  th {
    text-align: left;
  }

  th,
  td {
    @include u-border(1px, 'base-dark');
    background-color: color('white');
    font-weight: $theme-font-weight-normal;
    padding: units(1) units(2);
  }

  caption {
    @include u-font('serif', 'xs');
    font-weight: $theme-font-weight-bold;
    margin-bottom: units(1.5);
    text-align: left;
  }
}

%usa-table--borderless {
  thead {
    th {
      background-color: transparent;
      border-top: 0;
    }
  }

  th,
  td {
    border-left: 0;
    border-right: 0;
  }

  th {
    &:first-child {
      padding-left: 0;
    }
  }
}

.usa-table {
  @extend %usa-table;
}

.usa-table--borderless {
  @extend %usa-table--borderless;
}
