.usa-banner {
  @include typeset($theme-banner-font-family);
  @include border-box-sizing;
  background-color: color('base-lightest');

  @include at-media('tablet') {
    font-size: font-size($theme-banner-font-family, '3xs');
    padding-bottom: units(0);
  }
}

.usa-banner__content {
  @include grid-container($theme-site-max-width);
  @include add-responsive-site-margins;
  background-color: color('transparent');
  font-size: font-size($theme-banner-font-family, 4);
  overflow: hidden;
  padding-bottom: units(2);
  padding-left: units($theme-site-margins-mobile-width - 1);
  padding-top: units(0.5);
  width: 100%;

  @include at-media('tablet') {
    @include u-padding-y(3);
  }

  p {
    &:first-child {
      margin: 0;
    }
  }
}

.usa-banner__guidance {
  padding-top: units(2);

  @include at-media('tablet') {
    padding-top: units(0);
  }
}

.usa-banner__inner {
  @include add-responsive-site-margins;
  @include grid-container;
  @include grid-row;
  @include u-flex('align-start');
  padding-right: units(0);

  @include at-media('tablet') {
    @include u-flex('align-center');
  }
}

.usa-banner__header {
  @include u-padding-y(1);
  font-size: font-size($theme-banner-font-family, 1);
  font-weight: font-weight('normal');
  min-height: units($size-touch-target);
  position: relative;

  @include at-media('tablet') {
    @include u-padding-y(0.5);
    min-height: 0;
  }
}

.usa-banner__header-close-text {
  @include u-margin-y(0);
  color: color('base-dark');
  display: none;
  font-size: font-size($theme-banner-font-family, 1);
  line-height: line-height($theme-banner-font-family, 2);
  padding-top: units(0.5);

  .usa-banner__header--expanded & {
    display: block;

    @include at-media('tablet') {
      display: none;
    }
  }
}

.usa-banner__header-text {
  @include u-margin-y(0);
  font-size: font-size($theme-banner-font-family, 1);
  line-height: line-height($theme-banner-font-family, 2);
}

.usa-banner__header-action {
  @include add-icon('angle-arrow-down-primary', 'after', 1, 1, 0.5, 'hover');
  color: color('primary');
  line-height: line-height($theme-banner-font-family, 2);
  margin-bottom: units(0);
  margin-top: units(2px);
  text-decoration: underline;

  .usa-banner__header--expanded & {
    display: none;
  }

  @include at-media('tablet') {
    display: none;
  }
}

.usa-banner__header-flag {
  @include u-float('left');
  margin-right: units(1);
  width: units(2);

  @include at-media('tablet') {
    margin-right: units(1);
    padding-top: units(0);
  }
}

.usa-banner__header--expanded {
  padding-right: units($size-touch-target + 1);

  @include at-media('tablet') {
    background-color: transparent;
    color: color('ink');
    display: block;
    font-size: font-size($theme-banner-font-family, 1);
    font-weight: font-weight('normal');
    min-height: units(0);
    padding-right: units(0);
  }

  .usa-banner__inner {
    margin-left: units(0);

    @include at-media('tablet') {
      margin-left: units(auto);
    }
  }

  .usa-banner__header-action {
    display: none;
  }
}

.usa-banner__button {
  @include button-unstyled;
  @include u-pin('left');
  @include u-pin('y');
  @include u-text('primary', underline, baseline);
  display: block;
  font-size: font-size($theme-banner-font-family, 1);
  height: auto;
  line-height: line-height($theme-banner-font-family, 2);
  padding-top: units(0);
  padding-left: units(0);
  text-decoration: none;
  width: auto;

  @include at-media-max('tablet') {
    width: 100%;
  }

  @include at-media('tablet') {
    @include add-icon('angle-arrow-down-primary', 'after', 1, 1, 2px, 'hover');
    @include u-pin('none');
    display: inline;
    margin-left: units(1);
    position: relative;

    &:hover {
      @include u-text('primary-darker');
    }
  }

  &[aria-expanded=false] {
    background-image: none;
  }

  &[aria-expanded=true] {
    background-image: none;

    @include at-media-max('tablet') {
      @include add-icon('close-blue-60v-alt',
      'after',
      $theme-icon-image-size,
      $size-touch-target,
      0,
      'no-hover'
    );

      &::after {
        @include u-pin('y');
        @include u-pin('right');
        background-color: color('base-lighter');
        bottom: 0;
        height: auto;
      }
    }

    @include at-media('tablet') {
      @include add-icon('angle-arrow-up-primary', 'after', 1, 1, 2px, 'hover');
      height: auto;
      padding: units(0);
      position: relative;
    }

    &::after {
      position: absolute;
      right: units(2);
      top: units(1.5);

      @include at-media('tablet') {
        position: static;
      }
    }
  }
}

.usa-banner__button-text {
  @include add-sr-only;
  text-decoration: underline;

  @include at-media('tablet') {
    @include add-no-sr-only;
    display: inline;
  }
}

.usa-banner__icon {
  width: units(5);
}
